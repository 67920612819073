import axios from "axios";


export const getUserAddress = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/get-address`, {
    headers: {
      session: authtoken,
    },
    
  });
};

export const addAddress = async (authtoken,addAddress_) =>
  await axios.post(`${process.env.REACT_APP_API}/add-address`,addAddress_, {
    headers: {
      session: authtoken,
    },
  });

  export const removeAddress = async (addressID,authtoken) => {
    return await axios.delete(`${process.env.REACT_APP_API}/del-address`, {
      headers: {
        session: authtoken,
      },
      data:{
        id:addressID,
      }
    });
  };

  

    export const userID_ = async (authtoken) => {
      return await axios.get(`${process.env.REACT_APP_API}/get-user`, {
        headers: {
          session: authtoken,
        },
        // params:{
        //   id:getUserID_,
        // }
        
      });
    };
    export const editUser = async (authtoken,editUser_) =>
    await axios.patch(`${process.env.REACT_APP_API}/edit-user`,editUser_, {
      headers: {
        session: authtoken,
      },
     
    });
    
    export const getAddressID = async (authtoken,addressID) => {
      return await axios.get(`${process.env.REACT_APP_API}/get-address-id`, {
        headers: {
          session: authtoken,
        },
        params:{
          id:addressID,
        }
        
      });
    };

  


    export const editAddress = async (authtoken,addressID) =>
    await axios.patch(`${process.env.REACT_APP_API}/edit-address`,addressID, {
      headers: {
        session: authtoken,
      },
     
    });
    
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { updatePet, getPetID } from "../functions/pets";
import { LoadingOutlined } from "@ant-design/icons";
import { uploadImage } from "../functions/uploadImg";
import UploadPetImage from "../components/UploadPetImage";
import { useSelector } from "react-redux";


const EditPets = ({ match, history }) => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"))
  const token = user.token;
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState("");
  const [oldImg, setOldImg] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [age, setAge] = useState(0);
  const [breed, setBreed] = useState("");
  const [sex, setSex] = useState("");
  const [birthdate, setDate] = useState("");
  const maxDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    loadPets();
  }, []);

  const loadPets = () => {
    getPetID(token, match.params.id).then((res) => {
      setName(res.data.data[0].name);
      setType(res.data.data[0].type);
      setAge(res.data.data[0].age);
      setBreed(res.data.data[0].breed);
      setSex(res.data.data[0].sex);
      setDate(res.data.data[0].birthdate);
      setImg(res.data.data[0].img);
      setOldImg(res.data.data[0].img);
    })
    .catch((err) => {
      console.log("err: ", err);
    });
  };

  const handleYearPats = (e) => {

    setDate(e.target.value)
    
    const currentDate = new Date();
    const birthDate = new Date(e.target.value);
    const ageDiffMs = currentDate - birthDate;
    const ageDate = new Date(ageDiffMs);
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    if(calculatedAge === 0){
      setAge(1);
    }else{
      setAge(calculatedAge);
    }
  
    // loadAgepets();
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    //check when user change image
    if (img !== oldImg) {

      handlerUpdateImage();
    } else {
      let updatePet_ = {
        img: img,
        name: name,
        type: type,
        age: age,
        breed: breed,
        sex: sex,
        id: match.params.id,
        birthdate: birthdate,
      };
      handleUpdatePet(updatePet_);
    }
  };


  const handlerUpdateImage = async () => {
    let formData = new FormData();
    formData.append("file", img);
    formData.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
    formData.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
    formData.append("folder", "uat-moochie");

    try {
      const resp = await uploadImage(formData);

      let updatePet_ = {
        img: resp.data.secure_url,
        name: name,
        type: type,
        age: age,
        breed: breed,
        sex: sex,
        id: match.params.id,
        birthdate: birthdate,
      };
      handleUpdatePet(updatePet_);
    } catch (error) {
      console.log(error)
    }
    
  };

  const handleUpdatePet = (data) => {
    updatePet(token, data)
      .then((res) => {
  
        alert("บันทึกสำเร็จ");
        window.location.href = "/edit_profile";
      })
      .catch((err) => {
        console.log("err: ", err);
        alert("บันทึกไม่สำเร็จ");
      });
  };

  return (
    <div className="backgroundRegister_pets">
      <br></br>
    
        <Button className="botton_back" style={{ backgroundColor: '#FFF', borderColor: '#FFF' }} variant="light" href="/edit_profile"><b className="font_ButtonRegi_pets"> </b> </Button>
        <b className="Header_regis_pets "> แก้ไขสัตว์เลี้ยง</b>
      <br></br>
      <center>
        <Form.Label>อัพโหลดภาพสัตว์เลี้ยง</Form.Label>
      </center>
      <div className="image_upload">
        <UploadPetImage img={img} setImg={setImg} />
      </div>
      <br></br>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 ">
          <Form.Control
            placeholder="ชื่อ"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 ">
          <Form.Select
            aria-label="Default select example"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          >
            <option>ชนิด</option>
            <option value="dog">สุนัข</option>
            <option value="cat">แมว</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 ">
          <Form.Control
            placeholder="สายพันธุ์สัตว์เลี้ยง"
            value={breed}
            onChange={(e) => setBreed(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 ">
          <Form.Label>วันเดือนปีเกิด</Form.Label>
          <input type="date" className="form-control" value={birthdate} onChange={handleYearPats} style={{width:'100%'}} placeholder="วันเดือนปี" max={maxDate}></input>
          <br></br>
        </Form.Group>
        <Form.Group className="mb-3 " >
          <Form.Label>อายุ (ปี)</Form.Label>
          <Form.Control placeholder="อายุ" value={age} onChange={(e) => setAge(e.target.value)} disabled />
          <Form.Label style={{color:'#696969'}}>* หากอายุน้อยกว่า 1 ปีจะเท่ากับ 1 ปี</Form.Label>
        </Form.Group>

        <br></br>

        <div className="center">
          <table>
            <tr>
              <input
                type="radio"
                name="sex"
                value="female"
                onChange={(e) => setSex(e.target.value)}
                checked={sex === "female"}
              />
              &nbsp;&nbsp;ผู้หญิง
            </tr>
            <tr>
              <input
                type="radio"
                name="sex"
                value="male"
                onChange={(e) => setSex(e.target.value)}
                checked={sex === "male"}
              />
              &nbsp;&nbsp;ผู้ชาย
            </tr>
          </table>
        </div>

        <br></br>
        <center>
          <Form.Group controlId="formBasicCheckbox">
            {loading ? (
              <LoadingOutlined className="text-danger" />
            ) : (
              <Button
                style={{
                  backgroundColor: "#FF0099",
                  borderColor: "#FF0099",
                  width: "90px",
                }}
                variant="primary"
                type="submit"
                loadings
              >
                บันทึก{" "}
              </Button>
            )}
          </Form.Group>
        </center>
      </Form>
    </div>
  );
};

export default EditPets;

import axios from "axios";

export const topSpender = async (authtoken) => {
      return await axios.get(`${process.env.REACT_APP_API}/top-spender`, {
        headers: {
          session: authtoken,
        },  
      });
    };


import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Menu from "../components/menu";
import { topSpender } from "../functions/home";
import { myProfileRank } from "../functions/profile";
import Banner_KV from "../components/banner_KV";
import Banner_user from "../components/banner_user";
import Policy from "../components/policy";
import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Carousel from "react-bootstrap/Carousel";

const { confirm } = Modal;

const initialTopSpender = {
  member__firstname: "",
  member__surename: "",
};

const Home = ({ history }) => {
  // const { user } = useSelector((state) => ({ ...state }));

  const addressStatus = localStorage.getItem("addressStatus");
  const petStatus = localStorage.getItem("petStatus");
  const profireStatus = localStorage.getItem("member_status");
  const accepted_campaign = localStorage.getItem("accepted_campaign");
  const [token, setToken] = useState("");
  // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6OTUxLCJtZW1iZXJfaWQiOjQxLCJjcmVhdGVkX2F0IjoiMjAyMy0wNS0yMiAxNzowNDo0NiJ9.NX6IXis-fvRZVzh-2S9PhO0ArlnrJHG9BQeIeId4F7k";
  const [topSpender_, setTopSpender] = useState([]);
  let imgDefault =
    "https://res.cloudinary.com/dysfeos3s/image/upload/v1679385881/uat-moochie/edit_profile_petprofile_iodkjp.png";

  useEffect(() => {
    localStorage.removeItem("Page");
    if (addressStatus === "0" || petStatus === "0" || profireStatus === "0") {
      // let type = petStatus === "0" ? "pet" : "address";
      showPromiseConfirm();
    }

    const { user } = JSON.parse(window.localStorage.getItem("auth"));
    setToken(user.token);

    loadTopSpender(user.token);
    loadMyProfile(user.token);
  }, []);

  const showPromiseConfirm = () => {
    let msgTitle = " ";
    let msgDesc = " ";
    let path = " ";

    if (profireStatus === "0") {
      msgTitle = "กรุณาเพิ่มข้อมูลส่วนตัวให้ครบถ้วน";
      msgDesc = "กรุณาเพิ่มข้อมูลให้ครบถ้วน";
      path = "/edit_profile";
    } else if (addressStatus === "0") {
      msgTitle = "เพื่อรับสิทธิประโยชน์จากทางแบรนด์ตลอดทั้งปี";
      msgDesc = "กรุณาเพิ่มข้อมูลให้ครบถ้วน";
      path = "/add_address";
    } else if (petStatus === "0") {
      msgTitle = "กรุณาเพิ่ม ID Card ของสัตว์เลี้ยงให้ครบถ้วน";
      msgDesc = "กรุณาเพิ่ม ID Card ของสัตว์เลี้ยงให้ครบถ้วน";
      path = "/register_pets";
    }

    confirm({
      title: msgTitle,
      icon: <InfoCircleOutlined />,
      content: msgDesc,
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          history.push(path);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() { },
    });
  };

  const checkPermissionLuckyDraw = () => {
    // return false
    if (
      addressStatus === "1" &&
      petStatus === "1" &&
      profireStatus === "1" &&
      accepted_campaign === "true"
    ) {
      window.location.href = "lucky-draw-exchange";
    } else {
      window.location.href = "lucky-draw";
    }
  };

  const [number1, setNumber1] = useState([0]);
  const [number2, setNumber2] = useState([0]);
  const [number3, setNumber3] = useState([0]);
  const [number1Point, setNumber1Point] = useState(0);
  const [number2Point, setNumber2Point] = useState([0]);
  const [number3Point, setNumber3Point] = useState([0]);
  const [img1, setImg1] = useState(imgDefault);
  const [img2, setImg2] = useState(imgDefault);
  const [img3, setImg3] = useState(imgDefault);
  const [username, setUsername] = useState("");

  const [myImg, setMyImg] = useState(imgDefault);
  const [nameUser, setNameUser] = useState("");
  const [surnameUser, setSurnameUser] = useState("");
  const [totolPointUser, setTotolPointUser] = useState(0);
  const [myRanking, setMyRanking] = useState("");

  const loadTopSpender = async (token) => {
    topSpender(token).then((res) => {
      let topData = res.data.data;

      Object.keys(topData).forEach(function (index) {
        topData[index]["member__img"] = checkImage(
          topData[index]["member__img"]
        );
      });

      if (topData[2]) {
        setTopSpender(topData);

        setNumber1(topData[0]);
        setNumber1Point(topData[0].amount);
        setImg1(topData[0].member__img);
        setNumber2(topData[1]);
        setNumber2Point(topData[1].amount);
        setImg2(topData[1].member__img);
        setNumber3(topData[2]);
        setNumber3Point(topData[2].amount);
        setImg3(topData[2].member__img);
      } else {
        setTopSpender(topData);

        setNumber1(initialTopSpender);
        setNumber1Point(0);
        setImg1("");
        setNumber2(initialTopSpender);
        setNumber2Point(0);
        setImg2("");
        setNumber3(initialTopSpender);
        setNumber3Point(0);
        setImg3("");
      }
    });
  };

  const checkImage = (imgStr) => {
    if (imgStr) {
      return imgStr;
    }
    return imgDefault;
  };

  const loadMyProfile = async (token) => {
    myProfileRank(token).then((res) => {
      setMyImg(res.data.data.member__img);
      setNameUser(res.data.data.member__firstname);
      setSurnameUser(res.data.data.member__surname);
      setTotolPointUser(res.data.data.amount);
      setMyRanking(res.data.data.rank);
      setUsername(res.data.data.username);
    });
  };

  const checkPermission = () => {
    if (addressStatus === "1" && petStatus === "1" && profireStatus === "1") {
      history.push("/Upload_receipt");
    } else {
      showPromiseConfirm();
    }
  };

  return (
    <div>
      <div className="backgroundHome">
        <br></br>
        <center>
          <div className="d-flex align-items-center home_cam">
            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="dog_icon"
            ></div>
            <b className="Header">HOME CAMPAIGN</b>
            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="cat_icon"
            ></div>
          </div>
        </center>
        <br></br>

        <Form>
          <Banner_KV />
          <Carousel
            className="box_ฺBanner"
            activeIndex={0}
            onSelect={() => {
              console.log("onSelect");
            }}
          >
            <Carousel.Item
              style={{ cursor: "pointer" }}
              onClick={checkPermissionLuckyDraw}
            >
              <img
                className="d-block w-100 borderradius20px"
                src="assets/luckyDraw/MooChie_Lucky-draw-ครั้งที่-3-23-9-2024.png"
                alt="p1.ll"
              />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <br></br>

          <div className="ranking borderradius20px">
            <div className="rank1-3">
              <center>
                <Row className="rowhome">
                  <Col>
                    <div className="minmexSize2">
                      <div
                        className="circleRank2"
                        style={{ backgroundImage: `url(${img2})` }}
                      ></div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          marginLeft: "5%",
                          lineHeight: "15px",
                          marginTop: "10px",
                        }}
                      >
                        <x className="fontrank2_3">
                          {number2Point.toLocaleString("en-US")}
                        </x>
                        <br></br>
                        <x className="fontrank2_3_name">{number2.username}</x>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="minmexSize1">
                      <div
                        className="circleRank1"
                        style={{ backgroundImage: `url(${img1})` }}
                      ></div>
                      {/* <img className="circleRank1" src={img1}  /> */}
                      <br></br>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          marginLeft: "10%",
                          marginTop: "20px",
                          lineHeight: "15px",
                        }}
                      >
                        <x className="fontrank1_name">
                          {number1Point.toLocaleString("en-US")}
                        </x>
                        <br></br>
                        <x className="fontrank2_3_name">{number1.username}</x>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="minmexSize3">
                      <div
                        className="circleRank3"
                        style={{ backgroundImage: `url(${img3})` }}
                      ></div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <div style={{ lineHeight: "15px", marginTop: "10px" }}>
                        <x className="fontrank2_3">
                          {number3Point.toLocaleString("en-US")}
                        </x>
                        <br></br>
                        <x className="fontrank2_3_name">{number3.username}</x>
                      </div>
                    </div>
                  </Col>
                </Row>
              </center>
            </div>

            <div className="rankall font_rank_all px-4">
              {topSpender_.map((c, number) => {
                if (number > 2) {
                  return (
                    <div className="font_rank_all tr_home">
                      <Row className="d-flex align-items-center">
                        <Col xs={1}>
                          <b className="">
                            {"#"}
                            {number + 1}
                          </b>
                        </Col>
                        <Col xs={1}>
                          {" "}
                          <div
                            className="circle"
                            style={{ backgroundImage: `url(${c.member__img})` }}
                          ></div>
                        </Col>
                        <Col xs={7}>
                          <b style={{ marginLeft: "10%" }}>{c.username}</b>
                        </Col>
                        <Col>
                          <b>{c.amount.toLocaleString("en-US")}</b>
                        </Col>
                      </Row>
                    </div>
                  );
                }
              })}
            </div>
            <div className="MyRank">
              <Row className="d-flex align-items-center">
                <Col xs={1}>
                  <b className="">
                    {"#"}
                    {myRanking}
                  </b>
                </Col>
                <Col xs={1}>
                  {" "}
                  <div
                    className="circle"
                    style={{ backgroundImage: `url(${myImg})` }}
                  ></div>
                </Col>
                <Col xs={7}>
                  <b>{username}</b>
                </Col>
                <Col>
                  <b>{totolPointUser.toLocaleString("en-US")}</b>
                </Col>
              </Row>
            </div>
          </div>
          <br></br>
          <div>
            <Row className="d-flex align-items-center textUpload">
              <center>
                <Col>
                  <Button
                    style={{ color: "#FF0099" }}
                    className="botton_size"
                    variant="light"
                    onClick={() => checkPermission()}
                  // href="Upload_receipt"
                  >
                    <b className="font_ButtonUpload">เข้าร่วมแคมเปญ</b>
                  </Button>
                </Col>
              </center>
            </Row>
          </div>

          <Banner_user />

          <br></br>
          <div
            className="mb-3 resgisterFoodter rules"
            controlId="formBasicCheckbox"
          >
            <b>
              <h4 style={{ color: "#FF0099" }}>
                <Button
                  style={{ color: "#FF0099" }}
                  className="icon_3"
                  variant="light"
                >
                  <b className="font_ButtonUpload"> </b>{" "}
                </Button>
                &nbsp;&nbsp;กติกาและเงื่อนไข
              </h4>
            </b>

            <Policy />

            <br></br>
          </div>
        </Form>
        <div style={{ height: "100px" }}></div>
      </div>

      <Menu />
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { topSpenderPetShop } from "../functions/petShopAPI";
import { myProfileRank } from "../functions/profile";
import Banner_KV from "../components/banner_KV";
import Policy from "../components/policy";
import { Modal, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import RewardDetialPetshop from "../components/rewardDetialPetshop";
const { confirm } = Modal;

const PetShop = ({ history }) => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"))
  const addressStatus = localStorage.getItem("addressStatus");
  const petStatus = localStorage.getItem("petStatus");
  const token = user.token;
  // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MTAsIm1lbWJlcl9pZCI6MSwiY3JlYXRlZF9hdCI6IjIwMjMtMDMtMjEgMjI6MTk6MDEifQ.P6EDyjEpF9qw_yXP4Yg-rAmffUUDnR4QF6iOnwCulwc";
  const [topSpender, setTopSpender] = useState([]);
  let imgDefault =
    "https://res.cloudinary.com/dysfeos3s/image/upload/v1679385881/uat-moochie/edit_profile_petprofile_iodkjp.png";
  useEffect(() => {
    if (addressStatus === "0" || petStatus === "0") {
      let type = petStatus === "0" ? "pet" : "address";
      showPromiseConfirm(type);
    }

    loadTopSpender();

    loadMyProfile();
  }, []);

  const showPromiseConfirm = (type = "pet") => {
    let msgTitle = "เพื่อรับสิทธิพิเศษจากทางแบรนด์ตลอดทั้งปี";
    let msgDesc = "กรุณาเพิ่ม ID Card ของสัตว์เลี้ยงให้ครบถ้วน";
    let path = "/register_pets";

    if (type === "address") {
      msgTitle = "เพื่อรับสิทธิประโยชน์จากทางแบรนด์ตลอดทั้งปี";
      msgDesc = "กรุณาเพิ่มข้อมูลให้ครบถ้วน";
      path = "/add_address";
    }

    confirm({
      title: msgTitle,
      icon: <InfoCircleOutlined />,
      content: msgDesc,
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          history.push(path);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const [number1, setNumber1] = useState([0]);
  const [number2, setNumber2] = useState([0]);
  const [number3, setNumber3] = useState([0]);
  const [number1Point, setNumber1Point] = useState(0);
  const [number2Point, setNumber2Point] = useState([0]);
  const [number3Point, setNumber3Point] = useState([0]);
  const [img1, setImg1] = useState(imgDefault);
  const [img2, setImg2] = useState(imgDefault);
  const [img3, setImg3] = useState(imgDefault);
  const [username, setUsername] = useState("");

  const [myImg, setMyImg] = useState(imgDefault);
  const [nameUser, setNameUser] = useState("");
  const [surnameUser, setSurnameUser] = useState("");
  const [totolPointUser, setTotolPointUser] = useState(0);
  const [myRanking, setMyRanking] = useState("");

  const loadTopSpender = async () => {
    topSpenderPetShop(token).then((res) => {
      setTopSpender(res.data);
    });
  };

  const loadMyProfile = async () => {
    myProfileRank(token).then((res) => {
      setMyImg(res.data.data.member__img);
      setNameUser(res.data.data.member__firstname);
      setSurnameUser(res.data.data.member__surname);
      setTotolPointUser(res.data.data.amount);
      setMyRanking(res.data.data.rank);
      setUsername(res.data.data.username);
    });
  };

  return (
    <div>
      <div className="backgroundPetShop">
        <br></br>
        <center>
          <div className="d-flex align-items-center home_cam">
            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="dog_icon"
            ></div>
            <b className="Header">
              Pet Shop <br></br> TOP SELLER
            </b>
            <div
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="cat_icon"
            ></div>
          </div>
        </center>
        <br></br>

        <Form>
          <Banner_KV />

          <br></br>

          <div className="ranking borderradius20px">
            <div className="rank1-3PetShop">
              <center>
                <Row className="rowhome">
                  <Col>
                    <div className="minmexSize2">
                      <div
                        className="circleRank2"
                        style={{ backgroundImage: `url(${img2})` }}
                      ></div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          marginLeft: "5%",
                          lineHeight: "15px",
                          marginTop: "10px",
                        }}
                      >
                        <x className="fontrank2_3">
                          {number2Point.toLocaleString("en-US")}
                        </x>
                        <br></br>
                        <x className="fontrank2_3_name">{number2.username}</x>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="minmexSize1">
                      <div
                        className="circleRank1"
                        style={{ backgroundImage: `url(${img1})` }}
                      ></div>
                      {/* <img className="circleRank1" src={img1}  /> */}
                      <br></br>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          marginLeft: "10%",
                          marginTop: "20px",
                          lineHeight: "15px",
                        }}
                      >
                        <x className="fontrank1_name">
                          {number1Point.toLocaleString("en-US")}
                        </x>
                        <br></br>
                        <x className="fontrank2_3_name">{number1.username}</x>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="minmexSize3">
                      <div
                        className="circleRank3"
                        style={{ backgroundImage: `url(${img3})` }}
                      ></div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <div style={{ lineHeight: "15px", marginTop: "10px" }}>
                        <x className="fontrank2_3">
                          {number3Point.toLocaleString("en-US")}
                        </x>
                        <br></br>
                        <x className="fontrank2_3_name">{number3.username}</x>
                      </div>
                    </div>
                  </Col>
                </Row>
              </center>
            </div>

            {/* <div className="MyRank">
              <Row className="d-flex align-items-center">
                <Col xs={1}>
                  <b className="">
                    {"#"}
                    {myRanking}
                  </b>
                </Col>
                <Col xs={1}>
                  {" "}
                  <div
                    className="circle"
                    style={{ backgroundImage: `url(${myImg})` }}
                  ></div>
                </Col>
                <Col xs={7}>
                  <b>
                    {username}
                  </b>
                </Col>
                <Col>
                  <b>{totolPointUser.toLocaleString("en-US")}</b>
                </Col>
              </Row>
            </div> */}
          </div>
          <br></br>

          <RewardDetialPetshop />

          <br></br>
          {/* {JSON.stringify(topSpender)} */}
          <div className="rankallPetShop font_rank_all px-4">
            <div
              style={{
                height: "280px",
                overflow: "scroll",
                marginTop: "100px",
              }}
            >
              {topSpender.map((c, number) => {
                return (
                  <div className="font_rank_all tr_home">
                    <Row className="d-flex align-items-center">
                      <Col xs={1}>
                        <b className="">
                          {"#"}
                          {number + 1}
                        </b>
                      </Col>
                      <Col xs={1}>
                        {" "}
                        <div
                          className="circle"
                          style={{ backgroundImage: `url(${c.member__img})` }}
                        ></div>
                      </Col>
                      <Col xs={7}>
                        <Tooltip title={c.name} color={"black"} key={"black"}>
                          <b style={{ marginLeft: "10%" }}>
                            {c.name.substring(0, 15)}
                            {"..."}
                          </b>
                        </Tooltip>
                      </Col>
                      <Col>
                        {/* <b>{c.total_revenue.toLocaleString("en-US")}</b> */}
                        <b>{c.total_revenue}</b>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          </div>
          <br></br>

          <div
            className="mb-3 resgisterFoodter rules"
            controlId="formBasicCheckbox"
          >
            <b>
              <h4 style={{ color: "#FF0099" }}>
                <Button
                  style={{ color: "#FF0099" }}
                  className="icon_3"
                  variant="light"
                >
                  <b className="font_ButtonUpload"> </b>{" "}
                </Button>
                &nbsp;&nbsp;กติกาและเงื่อนไข
              </h4>
            </b>

            <Policy />

            <br></br>
          </div>
        </Form>
        <div style={{ height: "100px" }}></div>
      </div>
    </div>
  );
};

export default PetShop;

import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingToRedirect from "./LoadingToRedirect";
import { useDispatch } from "react-redux";

const UserRoute = ({ children, ...rest }) => {
  // const { user } = useSelector((state) => ({ ...state }));

  //---- UAT ใช้สำหรับจำลองเอา auth ในการ dev บนเครื่อง-------//

  // const data = {
  //   user: {
  //     firstname: "ฟหกฟหก",
  //     gender: "female",
  //     tel: "0844098902",
  //     birthdate: "2023-11-30",
  //     email: "asd@sad.com",
  //     img: "https://profile.line-scdn.net/0hoqbFQl6eMF5PPyNyX9BOIT9vMzRsTmlMal0qb38_bmclCHABZwx2bXI_aWp2X3IAZg53OntqPTpDLEc4UWnMakgPbW9zD38Ka1p4uQ",
  //     token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NTYsIm1lbWJlcl9pZCI6MTAxNCwiY3JlYXRlZF9hdCI6IjIwMjQtMTAtMDUgMTE6NTM6NDUifQ.djVL9tVaxVsLivbW882pr4WxNeDEJpxxQYgMShRPe0I"
  //   },
  //   tokens: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NTYsIm1lbWJlcl9pZCI6MTAxNCwiY3JlYXRlZF9hdCI6IjIwMjQtMTAtMDUgMTE6NTM6NDUifQ.djVL9tVaxVsLivbW882pr4WxNeDEJpxxQYgMShRPe0I"
  // };

  // localStorage.setItem("auth", JSON.stringify(data));
  // localStorage.setItem("lineAccessToken", "eyJhbGciOiJIUzI1NiJ9.h5ZN-AoUT61Ker2Y4-HHLbFUmIqwA3WgWy7vp9FGqNgV2kp-uQn3tnchzT8QURJrG04o5RbBBWHZ8XbGBZr4xv1IhrH4qXSEbuU2Jls6OusCorr1VE2RF-bv5VAAAj3FJAVcy4-hNNDqzvonv-Yg1lsHqRwUE-AS7SnsqZVHjYc.Wys__cC5F023Mr-Y2OsYW46aGWGIJnwNOd7NXHfYnuM")

  // สำหรับไว้เช็คที่อยู่
  // localStorage.setItem("addressStatus", '0')

  //---- UAT ใช้สำหรับหลอกเอา auth ในการ dev บนเครื่อง-------//

  const auth = JSON.parse(window.localStorage.getItem("auth"));



  return auth && auth.user ? <Route {...rest} /> : <LoadingToRedirect />;
};

export default UserRoute;

import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Menu from "../components/menu";
import UploadImage from "../components/upload_image";
import { Checkbox } from "antd";
import {
  getUserAddress,
  removeAddress,
  userID_,
  editUser,
} from "../functions/userAddress";
import { getPet, removePet } from "../functions/pets";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { uploadImage } from "../functions/uploadImg";
import { useSelector } from "react-redux";

const EditProfile = () => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"))
  const token = user.token;
  const [address, setAddress] = useState([]);
  const [getPets, setGetpets] = useState([]);
  const [userID, setUserID] = useState([]);

  const [username, setUsername] = useState("");
  const [img, setImg] = useState("");
  const [oldImg, setOldImg] = useState("");
  const [userIdFirstname, setuserIdFirstname] = useState("");
  const [userIdSurname, setuserIdSurname] = useState("");
  const [userIdGender, setuserIdGender] = useState("");
  const [userIdTel, setuserIdTel] = useState("");
  const [userIdBirthdate, setuserIdBirthdate] = useState("");
  const [userIdEmail, setuserIdEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const maxDate = new Date().toISOString().split("T")[0];
  let imgDefault =
    "https://res.cloudinary.com/dysfeos3s/image/upload/v1679385881/uat-moochie/edit_profile_petprofile_iodkjp.png";

  useEffect(() => {
    loadUserAddress();
    loadPets();
    loadUserID();
  }, []);

  const loadUserAddress = async () => {
    getUserAddress(token).then((res) => {
      setAddress(res.data.data);
    });
  };

  const loadPets = async () => {
    getPet(token).then((res) => {
      let showpats = res.data.data;
      Object.keys(showpats).forEach(function (index) {
        showpats[index]["img"] = checkImage(showpats[index]["img"]);
      });

      setGetpets(showpats);
    });
  };
  const checkImage = (imgStr) => {
    if (imgStr) {
      return imgStr;
    }
    return imgDefault;
  };

  const loadUserID = async () => {
    userID_(token).then((res) => {
      setUserID(res.data.data);

      setuserIdFirstname(res.data.data.firstname);
      setuserIdSurname(res.data.data.surname);
      setuserIdGender(res.data.data.gender);
      setuserIdTel(res.data.data.tel);
      setuserIdBirthdate(res.data.data.birthdate);
      setuserIdEmail(res.data.data.email);
      setImg(res.data.data.img);
      setOldImg(res.data.data.img);
      setUsername(res.data.data.username);
    });
  };

  const handleRemove = async (PetId) => {
    // let answer = window.confirm("Delete?");
    if (window.confirm("ต้องการลบสัตว์เลี้ยงหรือไม่ ?")) {
      removePet(PetId, token)
        .then((res) => {

          loadPets();
        })
        .catch((err) => {
          console.log("ลบไม่สำเร็จ :", err);
        });
    }
  };

  const handleRemoveAddress = async (addressID) => {
    // let answer = window.confirm("Delete?");
    if (window.confirm("ต้องการลบที่อยู่หรือไม่ ?")) {
      removeAddress(addressID, token)
        .then((res) => {
          loadUserAddress();
        })
        .catch((err) => {
          console.log("ลบไม่สำเร็จ :", err);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (img === "") {
      alert("กรุณาใส่รูปภาพ");
      setLoading(false);
    } else {
      if (img !== oldImg) {
        handlerUpdateImage();
      } else {
        let editUser_ = {
          firstname: userIdFirstname,
          surname: userIdSurname,
          gender: userIdGender,
          tel: userIdTel,
          birthdate: userIdBirthdate,
          email: userIdEmail,
          img: img,
          username: username,
        };
        handleUpdateProfile(editUser_);
      }
    }
  };

  const handlerUpdateImage = async () => {

    let data = new FormData();
    data.append("file", img);
    data.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
    data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
    data.append("folder", "uat-moochie");

    const resp = await uploadImage(data);

    let editUser_ = {
      firstname: userIdFirstname,
      surname: userIdSurname,
      gender: userIdGender,
      tel: userIdTel,
      birthdate: userIdBirthdate,
      email: userIdEmail,
      img: resp.data.secure_url,
    };
    handleUpdateProfile(editUser_);
  };

  const handleUpdateProfile = (data) => {
    console.table(data);
    editUser(token, data)
      .then((res) => {
        alert("บันทึกสำเร็จ");
        localStorage.setItem("member_status", "1");
        window.location.href = "/edit_profile";
      })
      .catch((err) => {
        console.log("err: ", err);
        alert("บันทึกไม่สำเร็จ");
        if (err.response) {
          // const res = err.response.data
          console.log("err.response: ", err.response);
          let { message, status_code } = err.response.data
          if (status_code === "400") {
            alert(message)
          } else {
            alert("something went wrong?")
          }
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="backgroundEditProfile">
        <div className=" d-flex align-items-center editprofile_cam">
          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="dog_icon"
          ></div>
          <b className="HeaderEditprofile">
            &nbsp;&nbsp;&nbsp;&nbsp;แก้ไข PROFILE&nbsp;&nbsp;&nbsp;&nbsp;
          </b>
          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="cat_icon"
          ></div>
        </div>
        <br></br>
        <center>
          <Form.Label className="">อัพโหลดภาพ Profile</Form.Label>
        </center>
        <div className="image_upload">

          <UploadImage
            img={img}
            onChange={(e) => setImg(e.target.value)}
            setImg={setImg}
          />
        </div>
        <br></br>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3 ">
            <Form.Label>ชื่อ</Form.Label>
            <Form.Control
              value={userIdFirstname}
              onChange={(e) => setuserIdFirstname(e.target.value)}
              placeholder="ชื่อ"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>สกุล</Form.Label>
            <Form.Control
              value={userIdSurname}
              onChange={(e) => setuserIdSurname(e.target.value)}
              placeholder="นามสกุล"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="username"
              maxLength={12}
              required
            />
          </Form.Group>

          <div className="center">
            <div className="d-flex flex-column mb-0">
              <div className="p-0">
                <Checkbox
                  value="female"
                  onChange={(e) => setuserIdGender(e.target.value)}
                  checked={userIdGender === "female"}
                >
                  &nbsp;&nbsp; ผู้หญิง
                </Checkbox>
              </div>
              <div className="p-0">
                <Checkbox
                  value="male"
                  onChange={(e) => setuserIdGender(e.target.value)}
                  checked={userIdGender === "male"}
                >
                  &nbsp;&nbsp; ผู้ชาย
                </Checkbox>
              </div>
              <div className="p-0">
                <Checkbox
                  value="LGBTQ+"
                  onChange={(e) => setuserIdGender(e.target.value)}
                  checked={userIdGender === "LGBTQ+"}
                >
                  &nbsp;&nbsp; LGBTQ+
                </Checkbox>
              </div>
            </div>
          </div>
          <br></br>

          <Form.Group className="mb-3">
            <Form.Label>หมายเลขโทรศัพท์</Form.Label>
            <Form.Control
              type="tel"
              name="tel"
              onChange={(e) => setuserIdTel(e.target.value)}
              value={userIdTel}
              placeholder="หมายเลขโทรศัพท์"
              required
            />
          </Form.Group>

          <Form.Label>วันเดือนปีเกิด</Form.Label>
          <input
            type="date"
            className="form-control"
            style={{ width: "100%" }}
            placeholder="วันเดือนปี"
            value={userIdBirthdate}
            max={maxDate}
            onChange={(e) => setuserIdBirthdate(e.target.value)}
            required
          ></input>
          <br></br>
          <Form.Group className="mb-3">
            <Form.Label>e-mail</Form.Label>
            <Form.Control
              onChange={(e) => setuserIdEmail(e.target.value)}
              value={userIdEmail}
              type="email"
              placeholder="moochie@email.com"
              required
            />
          </Form.Group>

          <br></br>
          <center>
            <Form.Group controlId="formBasicCheckbox">
              {loading ? (
                <LoadingOutlined className="text-danger" />
              ) : (
                <Button
                  style={{
                    backgroundColor: "#FF0099",
                    borderColor: "#FF0099",
                    width: "90px",
                  }}
                  variant="primary"
                  type="submit"
                  loadings
                >
                  บันทึก{" "}
                </Button>
              )}
            </Form.Group>
          </center>
        </Form>
        <br></br>
        <br></br>
        <center>
          <b className="AddressEditprofile">ADDRESS</b>
        </center>
        <br></br>
        <br></br>
        <div className="addressBox">
          {/* <Row className="d-flex ">
              <Col xs={2}>
                <img
                  className="circleAddress"
                  src={"/assets/edit profile_icon1_address pink.png"}
                  alt=""
                />
              </Col>
              &nbsp;&nbsp;&nbsp;
              <Col xs={7}>
                <div className="font_delivery">
                  <b>
                    loasdo asdsadof asdasdofasl asosdoasd asdofasfaoasda asdasd
                    asdas aasdasdasdasd asd asd asdasdasd asd asdasd as
                  </b>
                  <Button href="edit_address" style={{ color: '#778899' }}>
                    แก้ไขที่อยู่
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="AddressDelete">
                  <div className="Icon_AddressDelete"></div>
                </div>{" "}
              </Col>
            </Row>

            <Row className="d-flex ">
              <Col xs={2}>
                <img
                  className="circleAddress"
                  src={"/assets/edit profile_icon1_address.png"}
                  alt=""
                />
              </Col>
              &nbsp;&nbsp;&nbsp;
              <Col xs={7}>
                <div className="font_delivery">
                  <b>
                    loasdo asdsadof asdasdofasl asosdoasd asdofasfaoasda asdasd
                    asdas a
                  </b>
                  <p style={{ color: '#778899' }}>
                    แก้ไขที่อยู่
                  </p>
                </div>
              </Col>
              <Col>
                <div className="AddressDelete">
                  <div className="Icon_AddressDelete"></div>
                </div>{" "}
              </Col>
            </Row> */}

          {/* {JSON.stringify(address)} */}

          {address.map((c) => (
            <Row className="d-flex ">
              <Col xs={2}>
                <img
                  className="circleAddress"
                  src={"/assets/edit profile_icon1_address2.png"}
                  alt=""
                />
              </Col>
              &nbsp;&nbsp;&nbsp;
              <Col xs={7}>
                <div className="font_delivery">
                  <b>
                    {c.address} {c.district_name} {c.subdistrict_name}{" "}
                    {c.province_name} {c.zipcode_name}
                  </b>
                  <br></br>
                  <Link
                    to={`edit_address/${c.id}`}
                    style={{ color: "#778899", textDecoration: "none" }}
                  >
                    แก้ไขที่อยู่
                  </Link>
                </div>
              </Col>
              <Col>
                <div
                  className="AddressDelete"
                  onClick={() => handleRemoveAddress(c.id)}
                >
                  <div className="Icon_AddressDelete"></div>
                </div>{" "}
              </Col>
            </Row>
          ))}
        </div>

        <br></br>
        <center>
          <Form.Group controlId="formBasicCheckbox">
            <Button
              style={{
                backgroundColor: "#FFF",
                borderColor: "#FFF",
                width: "100%",
                fontSize: "15px",
                color: "#FF0099",
              }}
              variant="primary"
              type="submit"
              href="add_address"
            >
              เพิ่มที่อยู่ใหม่ +
            </Button>
          </Form.Group>
        </center>
        <br></br>
        <br></br>
        <center>
          <b className="AddressEditprofile" style={{ color: "#FFF" }}>
            รายชื่อสัตว์เลี้ยง
          </b>
        </center>
        <br></br>
        <br></br>

        <div className="addressBox">
          {getPets.map((c) => (
            <Row className="d-flex ">
              <Col xs={2}>
                <img alt="" className="circlePets" src={c.img} />
              </Col>
              &nbsp;&nbsp;&nbsp;
              <Col xs={7}>
                <div className="font_pets">{c.name}</div>
                <Link
                  to={`EditPets/${c.id}`}
                  style={{
                    color: "#778899",
                    paddingLeft: "10%",
                    textDecoration: "none",
                  }}
                >
                  แก้ไขสัตว์เลี้ยง
                </Link>
              </Col>
              <Col>
                <div
                  className="AddressDelete"
                  onClick={() => handleRemove(c.id)}
                >
                  <div className="Icon_AddressDelete"></div>
                </div>{" "}
              </Col>
            </Row>
          ))}
        </div>
        <br></br>
        <Button
          style={{
            backgroundColor: "#FFF",
            borderColor: "#FFF",
            width: "100%",
            fontSize: "15px",
            color: "#FF0099",
          }}
          variant="primary"
          type="submit"
          href="register_pets"
        >
          เพิ่มสัตว์เลี้ยงใหม่ +
        </Button>

        <div style={{ height: "100px" }}></div>
      </div>

      <Menu />
    </div>
  );
};

export default EditProfile;

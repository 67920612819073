import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "../components/menu";
import { getProvince, getShopProvinces } from "../functions/getProvince";
import { getShopType } from "../functions/uploadReceipt";
import { getShopOnlineType } from "../functions/uploadReceipt";
import { getEventShop } from "../functions/uploadReceipt";
import { getReceiptProduct } from "../functions/uploadReceipt";
import { addMeetAndGreet } from "../functions/uploadMeetAndGreet";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { uploadImage } from "../functions/uploadImg";
import UploadReceipt from "../components/uploadReceipt";
import { getCreditMeetAndGreet } from "../functions/uploadMeetAndGreet";

import { InputNumber, Select, Space, Radio, Modal, Col, Row } from "antd";
const { confirm } = Modal;

const Upload_meetandgreet = ({ history }) => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"));
  const token = user.token;
  const addressStatus = localStorage.getItem("addressStatus");
  const campaignType = localStorage.getItem("campaignType");
  const [type, setType] = useState("");
  const [img, setImg] = useState("");
  const [shop_id, setShop_id] = useState(0);
  const [price, setPrice] = useState(0);
  const [date, setDate] = useState("");
  const [other_shop, seOther_shop] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxDate = new Date().toISOString().split("T")[0];
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("cat"); // Default type


  const showPromiseConfirm = () => {
    let msgTitle = " ";
    let msgDesc = " ";
    let path = " ";

    // console.log("profireStatus",profireStatus)
    // console.log("addressStatus",addressStatus)
    // console.log("petStatus",petStatus)
    if (addressStatus === "0") {
      msgTitle = "เพื่อรับสิทธิประโยชน์จากทางแบรนด์";
      msgDesc = "กรุณาเพิ่มข้อมูลที่อยู่ให้ครบถ้วน";
      path = "/add_address";

      localStorage.setItem("Page", "campaign")


    }

    confirm({
      title: msgTitle,
      icon: <InfoCircleOutlined />,
      content: msgDesc,
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          history.push(path);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() { },
    });
  };
  const handleTypeChange = (e) => {
    setType(e.target.value);
    if (e.target.value === "other") {
      setShop_id(5);
    }
  };

  const handleRadioChange = (e) => {
    const newType = e.target.value;
    setSelectedType(newType);
    loadReceiptProduct(newType);
  };

  const handleChange = (value, type) => {
    // Preserve previously selected products
    const previousSelectedProducts = selectedProducts.filter((product) =>
      value.includes(product.id)
    );

    // Find and add newly selected products
    const newSelectedProducts = value
      .map((productId) => {
        const selectedProduct = ReceiptProduct.find(
          (item) => item.id === productId
        );
        if (selectedProduct) {
          return { id: selectedProduct.id, name: selectedProduct.name };
        } else {
          return null; // or handle the case when product is not found
        }
      })
      .filter(Boolean);

    // Combine previously selected and newly selected products
    const combinedSelectedProducts = [
      ...previousSelectedProducts,
      ...newSelectedProducts,
    ];

    // Remove duplicates from combinedSelectedProducts
    const uniqueSelectedProducts = combinedSelectedProducts.filter(
      (product, index, self) =>
        index === self.findIndex((p) => p.id === product.id)
    );

    setSelectedProducts(uniqueSelectedProducts);

    const updatedTypes = new Set(selectedProductTypes); // Create a copy of existing types
    updatedTypes.add(type); // Add the new type
    setSelectedProductTypes(updatedTypes);
  };

  useEffect(() => {

    if (addressStatus === "0") {
      // let type = petStatus === "0" ? "pet" : "address";
      showPromiseConfirm();
    } else {
      localStorage.removeItem("Page");
    }

    loadProvince();
    loadShopType();
    loadShopOnlineType();
    loadEventShop();
    loadReceiptProduct(selectedType);
    loadCreditMeetAndGreet();
    setPrice("");

  }, []);

  useEffect(() => {
    loadReceiptProduct(selectedType);
  }, [selectedType]);

  const [ShopType, setShopType] = useState([]);
  const loadShopType = async () => {
    getShopType(token).then((res) => {
      setShopType(res.data.data);
    });
  };

  const [ShopOnlineType, setShopOnlineType] = useState("");
  const loadShopOnlineType = async () => {
    getShopOnlineType(token).then((res) => {
      setShopOnlineType(res.data.data);
      setShop_id(res.data.data[0].id);
    });
  };

  const [EventShop, setEventShop] = useState("");
  const loadEventShop = async () => {
    getEventShop(token).then((res) => {
      if (res.data.data.length > 0) {
        setEventShop(res.data.data);
        setShop_id(res.data.data[0].id);
      } else {
        setEventShop([]);
        setShop_id(0);
      }
    });
  };

  //ดึงข้อมุลจังหวัดรวม
  const [provinces, setProvinces] = useState([]);
  const loadProvince = async () => {
    getProvince(token).then((res) => {
      setProvinces(res.data.data);
    });
  };


  const [CreditMeetAndGreet, setCreditMeetAndGreet] = useState();

  const loadCreditMeetAndGreet = async () => {
    getCreditMeetAndGreet(token).then((res) => {
      setCreditMeetAndGreet(res.data.data);
      console.log("res.data.data : ",res.data.data)
    }).catch((error) => {
      console.error("Error fetching data: ", error);
    });
  };

  //สร้างตัวแปรไว้ส่งค่าจังหวัดที่เลือก
  const [province, setProvince] = useState("");

  //ตัวแปรไว้เก็บค่าชื่อร้านค้าในจังหวัดทั้งหมด
  const [shopProvinces, setShopProvinces] = useState([]);

  //ฟังชัน onChang เมื่อเลือกจังหวัดแล้วจะส่งค่าไอดีมาเก็บไว้ที่ setProvince
  const handleProvince = (e) => {
    setProvince(e.target.value);

    //ส่งค่า api ของ id จังหวัดไปค้นหาชื่อร้านค่า (e.target.value)
    getShopProvinces(token, e.target.value).then((res) => {
      setShopProvinces(res.data.data);
    });
  };

  const [ReceiptProduct, setReceiptProduct] = useState([]);
  const loadReceiptProduct = async (type) => {
    try {
      const res = await getReceiptProduct(token, type);
      setReceiptProduct(res.data.data);
    } catch (error) {
      console.error("Error loading receipt products:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("selectedProducts", selectedProducts);
    // console.log("selectedProductTypes", selectedProductTypes);
    let pet_type;
    let product_id;

    if (addressStatus === "0") {
      // let type = petStatus === "0" ? "pet" : "address";
      showPromiseConfirm();
      return
    }

    if (selectedProductTypes.size === 1) {
      pet_type = selectedProductTypes.values().next().value;
    } else if (selectedProductTypes.size > 1) {
      pet_type = "both";
    } else {
      pet_type = "unknown";
    }
    if (price === "") {
      alert("กรุณาใส่ยอดเงิน");
    }
    if (campaignType === 'gold' && price < 500) {
      alert("ยอดเงินขั้นต่ำ 500 บาท");
      return
    }else if(campaignType === 'meet'){
      alert("ยอดเงินขั้นต่ำ 1,000 บาท");
      return
    }
    if (date === "") {
      alert("กรุณาใส่วันที่ซื้อ");
    }
    product_id = JSON.stringify(selectedProducts);
    if (img === "") {
      alert("กรุณาอัพโหลดรูปภาพ");
    }

    if (type === "online" && shop_id === 0) {
      alert("กรุณาเลือกร้านค้า");
    }

    if (type === "onsite" && shop_id === 0) {
      alert("กรุณาเลือกร้านค้า");
    }

    if (type === "event" && shop_id === 0) {
      alert("กรุณาเลือก Event");
    }

    setLoading(true);
    let data = new FormData();
    data.append("file", img);
    data.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
    data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
    data.append("folder", "uat-moochie");

    let authtoken = token;

    try {
      const resp = await uploadImage(data);

     
      let receipt = {
        img: resp.data.secure_url,
        shop_id: shop_id,
        price: price,
        pet_type: selectedType,
        product_id: product_id,
        date: date,
        other_shop: other_shop,
      };

      if (campaignType === 'gold') {
        receipt.receipt_type = 'gold';
      }

      console.log(" SUTMIT : ", receipt)
      addMeetAndGreet(authtoken, receipt)
        .then((res) => {
          setLoading(false);

          const page = "select_campaign";
          window.location.href = `alertSuscess/${page}`;
        })
        .catch((err) => {
          setLoading(false);
          console.log("err: ", err);
          alert("บันทึกไม่สำเร็จ");
        });
    } catch (error) {
      setLoading(false);
      console.log("ERROR :", error);
    }
  };

  const onChange = (value) => {
    setPrice(value);
  };

  return (
    <div>
      <div className="backgroundUpload">
        <div className=" d-flex align-items-center register_cam">
          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="dog_icon"
          ></div>
          <b className="Header_register">&nbsp;&nbsp;ส่งใบเสร็จ&nbsp;&nbsp;</b>

          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="cat_icon"
          ></div>
        </div>
        <center>
          {campaignType === 'meet' ? (
            <b style={{ color: '#FF0099', fontSize: '20px' }}>Meet & Greet</b>
          ) : campaignType === 'gold' ? (
            <b style={{ color: '#FF0099', fontSize: '20px' }}>Gold</b>
          ) : null}
        </center>
        <br></br>
        <div className="lucky_current_point" >
          <div style={{ color: "#ff3a89", textAlign: "center" }}>
            สิทธิ์ของฉัน
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: '10px'
            }}
          > */}
          {/* <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8893f97892e08b36b33b80d486605821e51076038917fa9baa1edaed4eccae92?apiKey=e95df7cd88704225a9c4058d8e5f379d&"
              style={{ marginRight: "30px", }}
            /> */}

          {/* <span style={{ marginRight: "15px", transform: "scale(1.5)", marginRight: "30px" }}> */}
          {/* 12314 {point}{" "} */}

          {/* {CreditMeetAndGreet !== undefined ? (
                <p>{CreditMeetAndGreet.toLocaleString()}</p>
              ) : (
                <p>0</p>
              )}

            </span> */}
          {/* <span>สิทธิ์</span> */}
          {/* </div> */}
          <Row>
            <Col span={8}>
              <div>
                <div>ทอง</div>
                <span style={{ marginRight: "15px", transform: "scale(1.5)", marginRight: "30px" }}>
                  {/* 12314 {point}{" "} */}

                  {CreditMeetAndGreet !== undefined ? (
                    <p>{CreditMeetAndGreet.credit_gold.toLocaleString()}</p>
                  ) : (
                    <p>0</p>
                  )}

                </span>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <div>ปกติ</div>
                <span style={{ marginRight: "15px", transform: "scale(1.5)", marginRight: "30px" }}>
                  {/* 12314 {point}{" "} */}

                  {CreditMeetAndGreet !== undefined ? (
                    <p>{CreditMeetAndGreet.credit_normal.toLocaleString()}</p>
                  ) : (
                    <p>0</p>
                  )}

                </span>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <div>vvip</div>
                <span style={{ marginRight: "15px", transform: "scale(1.5)", marginRight: "30px" }}>
                  {/* 12314 {point}{" "} */}

                  {CreditMeetAndGreet !== undefined ? (
                    <p>{CreditMeetAndGreet.credit_vvip.toLocaleString()}</p>
                  ) : (
                    <p>0</p>
                  )}

                </span>
              </div>
            </Col>
          </Row>
        </div>
        <Form onSubmit={handleSubmit}>
          <br></br>
          <center>
            <Form.Label className="">อัปโหลดภาพใบเสร็จ</Form.Label>
          </center>
          <div className="image_upload_receipt">
            <UploadReceipt img={img} setImg={setImg} />
          </div>
          <br></br>
          <Form.Group className="mb-3 input_money">
            <p style={{ color: "red", fontSize: "12px" }}>
              * ใบเสร็จเฉพาะวันที่ 8 ตุลาคม 2567 - 16 ธันวาคม 2567
            </p>
            
           
            {campaignType === 'meet' ? (
             <Form.Label>ยอดเงินของคุณ (ขั้นต่ำ 1,000 บาท) </Form.Label>
          ) : campaignType === 'gold' ? (
            <Form.Label> ยอดเงินของคุณ (ขั้นต่ำ 500 บาท)  </Form.Label>
          ) : null}

          

    

            <InputNumber
              type="tel"
              style={{ width: "100%" }}
              placeholder="ยอดเงินของคุณ"
              value={price}
              min={1}
              max={9999999}
              defaultValue={3}
              onChange={onChange}
            />
          </Form.Group>
          <div className="bath">&nbsp;บาท</div>
          <Form.Label style={{ width: "100%" }}>
            วันที่ซื้อ (ตามใบเสร็จ)
          </Form.Label>
          <input
            type="date"
            className="form-control"
            placeholder="วันเดือนปี"
            style={{ width: "100%" }}
            max={maxDate}
            onChange={(e) => setDate(e.target.value)}
          ></input>
          <br></br>
          <div id="boxproduct">
            <div id="product_box">
              <Space style={{ width: "100%" }} direction="vertical">
                <Radio.Group onChange={handleRadioChange} value={selectedType}>
                  <Space direction="vertical">
                    <Radio value="cat">Cat</Radio>
                    <Radio value="dog">Dog</Radio>
                  </Space>
                </Radio.Group>
                {/* <Select
                  className="form-control"
                  mode="multiple"
                  allowClear
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="เลือกสินค้าที่ซื้อ"
                  onChange={(value) => handleChange(value, selectedType)}
                  value={selectedProducts.map((product) => product.id)}
                  filterOption={(
                    input,
                    option // Custom filter function
                  ) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ReceiptProduct.map((item) => (
                    <Select.Option
                      key={`${item.id}-${item.name}`}
                      value={item.id}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select> */}
              </Space>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <div className="font_receipt">ซื้อจากที่ไหน</div>
          <Form.Label className="">ช่องทางการซื้อ</Form.Label>

          <Form.Select
            id="ddl"
            style={{ backgroundColor: "#F5F5F5" }}
            aria-label="Default select example"
            onChange={handleTypeChange}
          >
            <option>เลือกช่องทางการซื้อ</option>

            <option value="online">Online</option>
            <option value="onsite">Petshop</option>
            <option value="event">Event</option>
          </Form.Select>
          <br></br>

          {type === "online" && (
            <div id="box1">
              <div id="online_box">
                <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  value={shop_id}
                  onChange={(e) => setShop_id(e.target.value)}
                >
                  <option>
                    เลือกช่องทางการซื้อ
                  </option>

                  {/* {ShopOnlineType.map((item, index) => {
                    // setShop_id(item.id); if (index === 0)
                    if (index === 0) {
                      setShop_id(item.id);
                    }
                    return <option value={item.id}>{item.name}</option>;
                  })} */}
                  {ShopOnlineType.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
          )}
          {type === "onsite" && (
            <div id="box2">
              <div className="dropdown_receipt_offline" id="offline_box">
                <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  aria-label="Default select example"
                  onChange={handleProvince}
                  value={province}
                >
                  <option>จังหวัด</option>
                  {provinces.map((item) => (
                    <option value={item.id}>{item.name_th}</option>
                  ))}
                </Form.Select>
              </div>
              &nbsp;&nbsp;
              <div className="dropdown_receipt_offline">
                <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  aria-label="Default select example"
                  value={shop_id}
                  onChange={(e) => setShop_id(e.target.value)}
                >
                  <option>ชื่อร้าน</option>
                  {/* {shopProvinces.map((item, index) => {
                    if (index === 0) {
                      setShop_id(item.id);
                    }
                    return (
                      <option key={`shop${index}`} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })} */}
                  {shopProvinces.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
          )}
          {type === "event" && (
            <div id="box3">
              <div id="event_box">
                <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  value={shop_id}
                  onChange={(e) => setShop_id(e.target.value)}
                >
                  <option value="" selected="selected" disabled>
                    เลือกช่องทางการซื้อ
                  </option>
                  {/* {EventShop.map((item, index) => {
                    if (index === 0) {
                      setShop_id(item.id);
                    }
                    return (
                      <option key={`shop${index}`} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })} */}
                  {EventShop.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
          )}
          <br></br>

          <center>
            <Form.Group controlId="formBasicCheckbox">
              {loading ? (
                <LoadingOutlined className="text-danger" />
              ) : (
                <Button
                  style={{
                    backgroundColor: "#FF0099",
                    borderColor: "#FF0099",
                    width: "90px",
                  }}
                  variant="primary"
                  type="submit"
                  loadings
                >
                  บันทึก{" "}
                </Button>
              )}
            </Form.Group>
          </center>
        </Form>
        <br></br>
        <br></br>
        <div
          className="mb-3 resgisterFoodter rules"
          controlId="formBasicCheckbox"
        >
          <b>
            <h4 style={{ color: "#FF0099" }}>
              <Button
                style={{ color: "#FF0099" }}
                className="icon_3"
                variant="light"
              >
                <b className="font_ButtonUpload"> </b>{" "}
              </Button>
              &nbsp;&nbsp;กติกาและเงื่อนไข
            </h4>
          </b>

          <Row className="align-items-center displaychecklistDetail_home">
            <Col>
              <table>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>1. </td>
                  <td>
                    ระยะเวลากิจกรรม ร่วมสนุกได้ตั้งแต่วันที่ 4 มีนาคม 2567 ถึง 30
                    พฤศจิกายน 2567
                  </td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "text-top" }}>2. </td>
                  <td>การสะสมยอดซื้อเพื่อร่วมกิจกรรม Top spender</td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "text-top" }}>3. </td>
                  <td>
                    ของรางวัลแบ่งเป็น 3 ระดับ
                    ตามจำนวนยอดซื้อสินค้าจากทุกช่องทางที่สะสมตามลำดับ (Top spender)
                    <tr>
                      ระดับที่ 1 : เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางสูงที่สุด
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- ตั๋วเครื่องบินไป - กลับ 6 วัน5 คืน Bangkok -
                      Hokkaido จำนวน 2 ที่นั่ง มูลค่ากว่า 100,000 บาท จำนวน 1 รางวัล
                    </tr>
                    <tr>
                      ระดับที่ 2: เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 2
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- รางวัล ทองคำหนัก 50 สตางค์ มูลค่ากว่า 18,000
                      บาท รางวัลละ 1 เส้น จำนวน 1 รางวัล
                    </tr>
                    <tr>
                      ระดับที่ 3: เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 3
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- รางวัล Air pod pro มูลค่ากว่า 9,000 บาท
                      รางวัลละ 1 ชิ้น จำนวน 1 รางวัล ระดับที่ 4 - 10:
                      เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 4 - 10
                      ตามลำดับ
                    </tr>
                    <tr>
                      ระดับที่ 4 - 10:
                      เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 4 - 10
                      ตามลำดับ
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- รางวัล Gift set Moochie มูลค่า 3,000 บาท
                      รางวัลละ 1 เซ็ท จำนวน 7 รางวัล
                    </tr>
                  </td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "text-top" }}>4. </td>
                  <td>ของพรีเมียม Moochie สามารถใช้คะแนนสะสมจากยอดซื้อ แลกรับได้</td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "text-top" }}>5. </td>
                  <td>
                    การสะสมยอดซื้อตามกำหนดเพื่อเป็นสมาชิก MOOCHIE MEMBER
                    มีสิทธิ์รับสิทธิพิเศษตามระดับสมาชิก ดังนี้
                    <tr style={{ marginLeft: "5%" }}>
                      <td style={{ verticalAlign: "text-top" }}> - &nbsp;</td>
                      <td>Moochie Member: เมื่อสมัครเข้าร่วมกิจกรรม รับทันที</td>
                    </tr>
                    <tr style={{ marginLeft: "5%" }}>
                      <td style={{ verticalAlign: "text-top" }}> - &nbsp;</td>
                      <td>ยอดสั่งซื้อไม่มีขั้นต่ำ</td>
                    </tr>
                    <tr style={{ marginLeft: "5%" }}>
                      <td style={{ verticalAlign: "text-top" }}> - &nbsp;</td>
                      <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
                    </tr>
                    <br></br>
                    <tr>
                      <td></td>
                      <td>Moochie Silver Member :</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        มียอดสั่งซื้อสะสมครบ 5,000 บาท ขึ้นไป
                        และรักษายอดตลอดทั้งเดือนไม่ต่ำกว่า 700 บาท
                      </td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับโค้ดส่งสินค้าฟรี 2 ครั้ง/เดือน สำหรับสั่งสินค้าออนไลน์
                      </td>{" "}
                    </tr>
                    <br></br>
                    <tr>
                      <td></td>
                      <td>Moochie Gold Member :</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        มียอดสั่งซื้อสะสมครบ 15,000 บาท ขึ้นไป
                        และรักษายอดตลอดทั้งเดือนไม่ต่ำกว่า 1,500 บาท
                      </td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับโค้ดส่งสินค้าฟรี 5 ครั้ง/เดือน สำหรับสั่งสินค้าออนไลน์
                      </td>{" "}
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับส่วนลด 5% (สามารถใช้กับร้านออนไลน์ของทางแบรนด์เท่านั้น)
                      </td>{" "}
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับ Gift set ของ premium รุ่น Limited edition จาก Moochie
                        ทันที 1ครั้ง
                      </td>{" "}
                    </tr>
                    <br></br>
                    <tr>
                      <td></td>
                      <td>Moochie Platinum Member :</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        มียอดสั่งซื้อสะสมครบ 30,000 บาท ขึ้นไป
                        และรักษายอดตลอดทั้งเดือนไม่ต่ำกว่า 3,500 บาท
                      </td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับโค้ดส่งสินค้าฟรี 7 ครั้ง/เดือน สำหรับสั่งสินค้าออนไลน์
                      </td>{" "}
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับส่วนลด 10% (สามารถใช้กับร้านออนไลน์ของทางแบรนด์เท่านั้น)
                      </td>{" "}
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        ได้รับสิทธิ์ตรวจสุขภาพสัตว์เลี้ยงฟรี ปีละ 1 ครั้ง (Hato
                        wellness)
                      </td>{" "}
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับ Gift set ของ premium รุ่น Limited edition จาก Moochie
                        ทันที 1ครั้ง
                      </td>{" "}
                    </tr>
                    <br></br>
                    <tr>
                      <td></td>
                      <td>
                        หมายเหตุ: กรณีไม่มียอดซื้อขั้นต่ำตามกำหนดในแต่ละเดือน
                        จะไม่ได้รับสิทธิประโยชน์ในเดือนนั้น ๆแต่จะไม่ถูกลดระดับสิทธิ์
                        member{" "}
                      </td>{" "}
                    </tr>
                  </td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "text-top" }}>6. </td>
                  <td>
                    ทุก ๆ ยอดสินค้า 100 บาท = 1 คะแนน
                    (สามารถซื้อสินค้าเพื่อร่วมกิจกรรมสะสมคะแนนได้จากทุกช่องทาง)
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>7. </td>
                  <td>
                    ทุกคะแนนที่สะสมสามารถใช้แลกของพรีเมียม Moochie
                    ได้ตามที่แบรนด์กำหนด
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>8. </td>
                  <td>
                    ยอดซื้อสินค้าที่สะสมได้สูงสุดตามลำดับตั้งแต่ ลำดับที่ 1 – 10
                    มีสิทธิ์ได้รับรางวัลตามที่แบรนด์กำหนด
                  </td>
                </tr>
              </table>
              <br></br>
              วิธีการร่วมกิจกรรม
              <table>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>1. </td>
                  <td>
                    สะสมยอดซื้อจากใบเสร็จทุกช่องทาง (Online shop & Pet shop หรือ งาน
                    Event ของ Moochie ตลอดแคมเปญ)
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>2. </td>
                  <td>
                    อัปโหลดภาพใบเสร็จผ่านระบบ และรอ Admin approve ยอดใบเสร็จ
                    (ตัดยอดใบเสร็จทุก 18.00 น. ของทุกวัน)
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>3. </td>
                  <td>ทุกๆ ยอดซื้อ 100 บาท รับ 1 คะแนน</td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>4. </td>
                  <td>คะแนนสะสมจากยอดซื้อใบเสร็จ จะแสดงผลในวันถัดไป</td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>5. </td>
                  <td>
                    สามารถร่วมสนุกได้โดย ลงทะเบียนผ่าน LINE OFFICIAL MOOCHIE:
                    https://lin.ee/eszT5MR เท่านั้น
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>6. </td>
                  <td>
                    ผู้ร่วมกิจกรรมต้องกรอกข้อมูลตามที่ระบบกำหนดให้ครบถ้วน
                    เพื่อรับสิทธิ์ตามที่แบรนด์กำหนด
                  </td>{" "}
                </tr>
              </table>
              <br></br>
              กำหนดการประกาศรางวัล
              <p style={{ marginLeft: "5%" }}>
                กำหนดประกาศผู้ได้รับรางวัลตามยอดซื้อสะสม (Top spender) 10
                ลำดับวันศุกร์ ที่ 6 ธันวาคม 2567 เวลา 15.00 น. (วัน
                และเวลาอาจมีการเปลี่ยนแปลงตามที่บริษัทกำหนด) ทางเว็บไซต์กิจกรรม
                โดยสามารถเข้าผ่าน LINE OFFICIAL MOOCHIE: https://lin.ee/eszT5MR
              </p>
              <br></br>
              วิธีการประกาศรางวัล
              <p style={{ marginLeft: "5%" }}>
                บริษัทฯ จะประกาศผู้ได้รับรางวัลตามยอดซื้อสะสม (Top spender) 10 ลำดับ
                วันศุกร์ ที่ 6 ธันวาคม 2567 เวลา 15.00 น. (วัน
                และเวลาอาจมีการเปลี่ยนแปลงตามที่บริษัทกำหนด) ทางเว็บไซต์กิจกรรม
                โดยสามารถเข้าผ่าน LINE OFFICIAL MOOCHIE: https://lin.ee/eszT5MR
              </p>
              <br></br>
              วิธีการร่วมกิจกรรม
              <table>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>1. </td>
                  <td>
                    กิจกรรมเริ่มตั้งแต่วันที่ 4 มีนาคม 2567 ถึง 30 พฤศจิกายน 2567
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>2. </td>
                  <td>
                    สิ่งของรางวัลไม่สามารถนำไปแลกเป็นเงินสดหรือสิ่งของอื่นใดได้
                    ไม่สามารถโอนให้ผู้อื่นได้ และไม่มีการจ่ายเงินเป็นส่วนประกอบ
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>3. </td>
                  <td>
                    การจับรางวัลจะประกาศผู้ได้รับรางวัลตามยอดซื้อสะสม (Top spender) 10
                    ลำดับ{" "}
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>4. </td>
                  <td>
                    ผู้โชคดีที่ได้รับรางวัลทั้งที่มีภูมิลำเนาในกรุงเทพมหานคร
                    และต่างจังหวัดต้องนำหลักฐานบัตรประจำตัวประชาชน พร้อมสำเนา 1 ชุด
                    มาเป็นหลักฐานในการขอรับรางวัลด้วยตนเองที่บริษัท ไทยออซัม จำกัด
                    (มหาชน) สำนักงานแห่งใหญ่ตั้งอยู่เลขที่ 108/126 ซอยแจ้งวัฒนะ 20
                    ถนนแจ้งวัฒนะ ตำบลปากเกร็ด อำเภอปากเกร็ด จังหวัดนนทบุรี
                    ในวันและเวลาทำการภายในระยะ 7 วันนับแต่วันที่ประกาศรายชื่อผู้โชคดี
                    หากเลยกำหนดถือว่าสละสิทธิ์ และบริษัทฯ
                    จะมอบของรางวัลดังกล่าวให้กับองค์กรสาธารณกุศลต่อไป
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>5. </td>
                  <td>
                    บริษัทฯ มีสิทธิ์คัดเลือกรายชื่อผู้ได้รับรางวัลสำรองไว้
                    สำหรับกรณีที่ผู้มีสิทธิ์ได้รับรางวัล และ/หรือ
                    ผู้ได้รับรางวัลต้องถูกตัดสิทธิ์จากการรับรางวัลเนื่องจากมีคุณสมบัติไม่ครบถ้วนหรือไม่ปฏิบัติตามกติกาและเงื่อนไขของการจับรางวัลที่กำหนดไว้
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>6. </td>
                  <td>
                    ผู้โชคดีที่ได้รับรางวัลมูลค่า 1,000 บาทขึ้นไป จะต้องหักภาษี ณ
                    ที่จ่าย ของมูลค่ารางวัล ตามคำสั่ง กรมสรรพากร ที่ ท.ป. 4/2528
                    ประกอบคำสั่งกรมสรรพากรที่ ท.ป. 104/2544 และ
                    ค่าธรรมเนียมและค่าภาษีอื่น ๆ
                    ที่เกิดขึ้นนอกเหนือจากมูลค่าสิ่งของรางวัลที่ได้รับ
                    ผู้โชคดีจะต้องเป็นผู้รับผิดชอบค่าใช้จ่ายเอง
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>7. </td>
                  <td>
                    ค่าธรรมเนียม ค่าภาษีอื่น ค่าใช้จ่ายอื่น และ ส่วนเกินมูลค่าสินค้า
                    ที่เกิดขึ้นนอกเหนือจากมูลค่าสิ่งของรางวัลที่ได้รับ
                    ผู้โชคดีจะต้องเป็นผู้รับผิดชอบค่าใช้จ่ายเอง สอบถามข้อมูล
                    และตรวจสอบเงื่อนไขเพิมเติมได้ที่ .บริษัท ไทยออซัม จำกัด (มหาชน)
                    02-8215545
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>8. </td>
                  <td>
                    บริษัทฯ เป็นเพียงผู้จัดหาของรางวัลเพื่อรายการชิงโชคนี้เท่านั้น
                    โดยบริษัทฯ มิได้มีส่วนเกี่ยวข้องกับการผลิต และ/หรือการจำหน่าย
                    และ/หรือ การให้บริการ ในของรางวัลที่ระบุไว้แต่อย่างใด ดังนั้น
                    บริษัทฯ จึงไม่ต้องรับผิดชอบในความเสียหาย บุบสลาย ชำรุดบกพร่อง
                    และ/หรือ ในเหตุอื่นใดอันเกิดขึ้นกับของรางวัลทั้งสิ้น
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>9. </td>
                  <td>
                    พนักงานบริษัท ไทยออซัม จำกัด (มหาชน) ,
                    บริษัทตัวแทนโฆษณาที่เกี่ยวข้อง คณะกรรมการดำเนินรายการและครอบครัว
                    ไม่มีสิทธิ์เข้าร่วมชิงรางวัลในรายการ
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>10. </td>
                  <td>
                    สำหรับกิจรรมสะสมคะแนนเพื่อลุ้นรับรางวัล
                    ทางทีมจะตรวจสอบข้อมูลเมื่อครบถ้วน และถูกต้องแล้ว
                    จะดำเนินการทยอยจัดส่งของรางวัลไปตามที่อยู่ที่ได้ลงทะเบียน
                    โดยการจัดส่งของรางวัลจะจัดส่งเป็นรอบๆทุก 7 วัน
                    ตลอดจนหมดระยะเวลาของกิจกรรม
                  </td>{" "}
                </tr>
              </table>
            </Col>
          </Row>

          <br></br>
        </div>
        <div style={{ height: "100px" }}></div>
      </div>
      {/* <Menu /> */}
    </div>
  );
};

export default Upload_meetandgreet;

import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { getUserAddress } from "../functions/userAddress";
import { profile } from "../functions/profile";
import { addSendRedeem } from "../functions/redeem";
import { useSelector } from "react-redux";

const Delivery = ({ match }) => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"))
  const token = user.token;
  const lineToken = localStorage.getItem("lineAccessToken");
  const [address, setAddress] = useState([]);
  const [member_address_id, setIdAddresss] = useState(0);
  const [MyPoint, setPoint] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [surname, setSurname] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  // const name_ = `${firstname} ${surname}`;

  const product_id = parseInt(match.params.id);
  const point = parseInt(match.params.point);
  localStorage.setItem("Point_Storage", point);
  localStorage.setItem("product_id_Storage", product_id);
  const product_id_Input = parseInt(localStorage.getItem("product_id_Storage"));
  const point_input = parseInt(localStorage.getItem("Point_Storage"));

  useEffect(() => {
    loadUserAddress();
    loadProfile();
  }, []);

  const loadUserAddress = async () => {
    getUserAddress(token).then((res) => {
      setAddress(res.data.data);
    });
  };

  const loadProfile = async () => {
    profile(token, lineToken).then((res) => {
      setFirstname(res.data.data.firstname);
      setSurname(res.data.data.surname);
      setTel(res.data.data.tel);
      setEmail(res.data.data.email);
      setPoint(res.data.data.point[0].point);

    });
  };

  const handleIdAdress = (e) => {

    setIdAddresss(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let authtoken = token;
    let sendRedeem = {
      product_id: product_id_Input,
      point: point_input,
      member_address_id: member_address_id,
      firstname: firstname,
      surname: surname,
      tel: tel,
      email: email,
    };

    console.table(sendRedeem);
    addSendRedeem(authtoken, sendRedeem)
      .then((res) => {
        alert("บันทึกสำเร็จ");
        window.location.href = "/redeem_rewards";
      })
      .catch((err) => {

        if (MyPoint < point) {
          alert("พ้อยของคุณไม่พอ");
          window.location.href = "/redeem_rewards";
        } else {
          alert("บันทึกไม่สำเร็จ");
        }

        // window.location.reload(false);alertSuscess
      });
  };

  return (
    <div className="backgroundMain" style={{ height: "1000px" }}>
      <br></br>
      <center className="d-flex align-items-center">
        <Button
          className="botton_back"
          style={{ backgroundColor: "#FFF", borderColor: "#FFF" }}
          variant="light"
          href="/redeem_rewards"
        >
          <b className="font_ButtonUpload"> </b>{" "}
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        <b className="Header_regis_pets "> ที่อยู่จัดส่ง</b>
      </center>
      <br></br>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 ">
          <Form.Label>ช่องทางติดต่อ</Form.Label>
          <Form.Control
            style={{ backgroundColor: "#F5F5F5" }}
            placeholder="ชื่อ"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 ">
          <Form.Control
            style={{ backgroundColor: "#F5F5F5" }}
            placeholder="นามสกุล"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 ">
          <Form.Control
            style={{ backgroundColor: "#F5F5F5" }}
            placeholder="หมายเลขโทรศัพท์"
            value={tel}
            onChange={(e) => setTel(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3 ">
          <Form.Control
            style={{ backgroundColor: "#F5F5F5" }}
            placeholder="อีเมลล์"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <br></br>
        <Form.Label>เลือกที่อยู่จัดส่ง</Form.Label>
        <div className="addressBox">
          <Form.Select
            Districts
            style={{ backgroundColor: "#F5F5F5", fontSize: "14px" }}
            aria-label="Default select example"
            value={member_address_id}
            onChange={handleIdAdress}
            required
          >
            {/* <option vualue={subdistrictIdOutput}>{subdistrictNameOutput}</option> */}
            <option>เลือกที่อยู่</option>
            {address.map((c) => (
              <option value={c.id}>
                {c.address} {c.district_name} {c.subdistrict_name}{" "}
                {c.province_name} {c.zipcode_name}
              </option>
            ))}
          </Form.Select>
        </div>

        <br></br>
        <center>
          <Form.Group controlId="formBasicCheckbox">
            <Button
              style={{
                backgroundColor: "#FFF",
                borderColor: "#FFF",
                width: "100%",
                fontSize: "15px",
                color: "#FF0099",
              }}
              variant="primary"
              href={"/add_addressRedeem"}
            >
              เพิ่มที่อยู่ใหม่ +
            </Button>
          </Form.Group>
        </center>
        <br></br>

        <center>
          <Form.Group controlId="formBasicCheckbox">
            <Button
              style={{
                backgroundColor: "#FF0099",
                borderColor: "#FF0099",
                width: "90px",
              }}
              variant="primary"
              type="submit"
            >
              ยืนยัน{" "}
            </Button>
          </Form.Group>
        </center>
      </Form>
    </div>
  );
};

export default Delivery;

import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "../components/menu";
import Card from "react-bootstrap/Card";
import { getRedeem } from "../functions/redeem";
import { profile } from "../functions/profile";
import { useSelector } from "react-redux";

const Redeem_rewards = () => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"))
  const token = user.token;
  const lineToken = localStorage.getItem("lineAccessToken");

  useEffect(() => {
    loadRedeem();
    loadProfile();
  }, []);

  const [getRedeem_, setGetRedeem] = useState([]);
  const [Mypoint, setPoint] = useState(0);

  const loadRedeem = async () => {
    getRedeem(token).then((res) => {
      setGetRedeem(res.data.data);
    });
  };
  const loadProfile = async () => {
    profile(token, lineToken).then((res) => {
      setPoint(res.data.data.point[0].point);
    });
  };

  const handleDelivery = (id, point) => {
  
    if (Mypoint < point) {
      alert("แต้มของคุณไม่พอ");
      window.location.href = "";
    } else {
      window.location.href = `delivery/${id}/${point}`;
    }
  };

  return (
    <div>
      <div className="backgroundRedeem_rewards">
        <div className=" d-flex align-items-center redeem_cam">
          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="dog_icon_redeem"
          ></div>
          <b className="Header_redeem">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;แลกของรางวัล&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </b>
          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="cat_icon_redeem"
          ></div>
        </div>
        <br></br>
        <p className="MyPoint_redeem my-0">คะแนนคงเหลือของคุณ</p>
        <div>
          <center>
            <p style={{ color: "#FFF", fontSize: "22px" }}>
              {" "}
              <b style={{ fontSize: "30px", color: "#FFF" }}>
                {Mypoint.toLocaleString("en-US")}
              </b>{" "}
              คะแนน
            </p>
          </center>
        </div>
        <br></br>

        <div className="overflowRedeem">
          {getRedeem_.map((c) => (
            <Card className="marginAutoRedeem" style={{ borderRadius: "35px" }}>
              <img className="image_redeem" src={c.img} alt="" />

              <Card.Body>
                <Card.Title className="NameRedeem">
                  <b style={{ fontSize: "24px" }}>{c.name}</b>
                </Card.Title>
                <center>
                  <Card.Text>
                    <x style={{ fontSize: "14px", color: "#828282" }}>
                      {c.description}
                    </x>{" "}
                  </Card.Text>
                </center>
                <br></br>
                <Card.Text>
                  <b style={{ fontSize: "20px" }}>
                    {c.point.toLocaleString("en-US")}
                  </b>{" "}
                  <b className="pointfont">คะแนน</b>
                </Card.Text>

                <Button
                  className="bottonRedmeem"
                  style={{
                    backgroundColor: "#ff3a89",
                    borderColor: "#ff3a89",
                    borderRadius: "25px",
                  }}
                  // href={`delivery/${c.id}/${c.point}`}
                  onClick={() => handleDelivery(c.id, c.point)}
                >
                  <b className="bottonFont">แลกของรางวัล</b>
                </Button>
              </Card.Body>
            </Card>
          ))}

          <div style={{ height: "100px" }}></div>
        </div>
      </div>

      <Menu />
    </div>
  );
};

export default Redeem_rewards;

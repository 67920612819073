import { Button, Result, Col, Row } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SuccessRegisterPage = ({ route = "home", message = "ลงทะเบียนสำเร็จ", history }) => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"))

  useEffect(() => {
    if (user && user.token) history.push("/home");
  }, [user, history]);

  return (
    <div style={{height:"100vh"}}>
      <Row justify="space-around" align="middle" className="h-100">
        <Col>
          <Result
            status="success"
            title={message}
            extra={[
              <Link to={`/${route}`}>
                <Button type="primary" key="console">
                  Next
                </Button>
                ,
              </Link>,
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SuccessRegisterPage;

import axios from "axios";

export const User_info = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/lucky-draw`, {
    headers: {
      session: authtoken,
    },

  });
};

export const Lucky_draw_redeem = async (authtoken, data) => {
  return await axios.post(`${process.env.REACT_APP_API}/redeem-point`, data, {
    headers: {
      session: authtoken,
    },
  });
}

export const Accept_lucky_draw = async (authtoken, data) => {
  return await axios.patch(`${process.env.REACT_APP_API}/edit-user`, data, {
    headers: {
      session: authtoken,
    },
  });
}


import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getPet } from "../functions/pets";
import { useSelector } from "react-redux";

function ControlledCarousel({ nameUser, surnameUser, slidepets, telUser }) {

  const { user } = JSON.parse(window.localStorage.getItem("auth"))
  const token = user.token;
  const lineToken = localStorage.getItem("lineAccessToken");
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    loadPets();
  }, []);

  const [getPets, setGetpets] = useState([]);

  const loadPets = async () => {
    getPet(token, lineToken).then((res) => {
      setGetpets(res.data.data);
    });
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {slidepets.map((c) => (
        <Carousel.Item>
          <div className="box_profile_pets">
            <div className="IN_box_profile_pets">
              <x
                style={{
                  fontSize: "18px",
                  color: "#FFF",
                  marginLeft: "1%",
                  textShadow: "black 0.1em 0.1em 0.2em",
                }}
              >
                ID : {c.id}{" "}
              </x>
              <div className="Sub1_box_profile_pets">
                &nbsp;
                <b style={{ textShadow: "black 0.1em 0.1em 0.2em" }}>
                  {c.name}
                </b>
              </div>

              <center>
                {/* <div className="frame_pets"></div> */}
                <img className="frame_pets" src={c.img} alt="" />
              </center>

              <div className="Sub2_box_profile_pets">
                <Row
                  className="d-flex align-items-center"
                  style={{ lineHeight: "13px" }}
                >
                  <Col xs={6}>
                    <b
                      style={{
                        color: "#FFF",
                        fontSize: "12px",
                        textShadow: "black 0.1em 0.1em 0.2em",
                      }}
                    >
                      สายพันธุ์ : {c.breed}
                    </b>
                    <br></br>
                    <x
                      style={{
                        fontSize: "12px",
                        color: "#FFF",
                        textShadow: "black 0.1em 0.1em 0.2em",
                      }}
                    >
                      เกิดวันที่ {c.birthdate}
                    </x>
                  </Col>
                  <Col>
                    {" "}
                    <x
                      style={{
                        fontSize: "10px",
                        color: "#FFF",
                        float: "right",
                        paddingRight: "21%",
                        textShadow: "black 0.1em 0.1em 0.2em",
                      }}
                    >
                      {nameUser} {surnameUser}
                    </x>
                    <br></br>
                    <x
                      style={{
                        fontSize: "10px",
                        color: "#FFF",
                        float: "right",
                        paddingRight: "21%",
                        textShadow: "black 0.1em 0.1em 0.2em",
                      }}
                    >
                      {telUser}
                    </x>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ControlledCarousel;

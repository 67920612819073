import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Policy = () => {
  return (
    <Row className="align-items-center displaychecklistDetail_home">
      <Col>
        <table>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>1. </td>
            <td>
              ระยะเวลากิจกรรม ร่วมสนุกได้ตั้งแต่วันที่ 4 มีนาคม 2567 ถึง 30
              พฤศจิกายน 2567
            </td>
          </tr>

          <tr>
            <td style={{ verticalAlign: "text-top" }}>2. </td>
            <td>การสะสมยอดซื้อเพื่อร่วมกิจกรรม Top spender</td>
          </tr>

          <tr>
            <td style={{ verticalAlign: "text-top" }}>3. </td>
            <td>
              ของรางวัลแบ่งเป็น 3 ระดับ
              ตามจำนวนยอดซื้อสินค้าจากทุกช่องทางที่สะสมตามลำดับ (Top spender)
              <tr>
                ระดับที่ 1 : เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางสูงที่สุด
              </tr>
              <tr>
                &nbsp;&nbsp;&nbsp;- ตั๋วเครื่องบินไป - กลับ 6 วัน5 คืน Bangkok -
                Hokkaido จำนวน 2 ที่นั่ง มูลค่ากว่า 100,000 บาท จำนวน 1 รางวัล
              </tr>
              <tr>
                ระดับที่ 2: เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 2
              </tr>
              <tr>
                &nbsp;&nbsp;&nbsp;- รางวัล ทองคำหนัก 50 สตางค์ มูลค่ากว่า 18,000
                บาท รางวัลละ 1 เส้น จำนวน 1 รางวัล
              </tr>
              <tr>
                ระดับที่ 3: เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 3
              </tr>
              <tr>
                &nbsp;&nbsp;&nbsp;- รางวัล Air pod pro มูลค่ากว่า 9,000 บาท
                รางวัลละ 1 ชิ้น จำนวน 1 รางวัล ระดับที่ 4 - 10:
                เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 4 - 10
                ตามลำดับ
              </tr>
              <tr>
                ระดับที่ 4 - 10:
                เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 4 - 10
                ตามลำดับ
              </tr>
              <tr>
                &nbsp;&nbsp;&nbsp;- รางวัล Gift set Moochie มูลค่า 3,000 บาท
                รางวัลละ 1 เซ็ท จำนวน 7 รางวัล
              </tr>
            </td>
          </tr>

          <tr>
            <td style={{ verticalAlign: "text-top" }}>4. </td>
            <td>ของพรีเมียม Moochie สามารถใช้คะแนนสะสมจากยอดซื้อ แลกรับได้</td>
          </tr>

          <tr>
            <td style={{ verticalAlign: "text-top" }}>5. </td>
            <td>
              การสะสมยอดซื้อตามกำหนดเพื่อเป็นสมาชิก MOOCHIE MEMBER
              มีสิทธิ์รับสิทธิพิเศษตามระดับสมาชิก ดังนี้
              <tr style={{ marginLeft: "5%" }}>
                <td style={{ verticalAlign: "text-top" }}> - &nbsp;</td>
                <td>Moochie Member: เมื่อสมัครเข้าร่วมกิจกรรม รับทันที</td>
              </tr>
              <tr style={{ marginLeft: "5%" }}>
                <td style={{ verticalAlign: "text-top" }}> - &nbsp;</td>
                <td>ยอดสั่งซื้อไม่มีขั้นต่ำ</td>
              </tr>
              <tr style={{ marginLeft: "5%" }}>
                <td style={{ verticalAlign: "text-top" }}> - &nbsp;</td>
                <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
              </tr>
              <br></br>
              <tr>
                <td></td>
                <td>Moochie Silver Member :</td>
              </tr>
              <tr>
                <td>- </td>
                <td>
                  มียอดสั่งซื้อสะสมครบ 5,000 บาท ขึ้นไป
                  และรักษายอดตลอดทั้งเดือนไม่ต่ำกว่า 700 บาท
                </td>
              </tr>
              <tr>
                <td>- </td>
                <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
              </tr>
              <tr>
                <td>- </td>
                <td>
                  รับโค้ดส่งสินค้าฟรี 2 ครั้ง/เดือน สำหรับสั่งสินค้าออนไลน์
                </td>{" "}
              </tr>
              <br></br>
              <tr>
                <td></td>
                <td>Moochie Gold Member :</td>
              </tr>
              <tr>
                <td>- </td>
                <td>
                  มียอดสั่งซื้อสะสมครบ 15,000 บาท ขึ้นไป
                  และรักษายอดตลอดทั้งเดือนไม่ต่ำกว่า 1,500 บาท
                </td>
              </tr>
              <tr>
                <td>- </td>
                <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
              </tr>
              <tr>
                <td>- </td>
                <td>
                  รับโค้ดส่งสินค้าฟรี 5 ครั้ง/เดือน สำหรับสั่งสินค้าออนไลน์
                </td>{" "}
              </tr>
              <tr>
                <td>- </td>
                <td>
                  รับส่วนลด 5% (สามารถใช้กับร้านออนไลน์ของทางแบรนด์เท่านั้น)
                </td>{" "}
              </tr>
              <tr>
                <td>- </td>
                <td>
                  รับ Gift set ของ premium รุ่น Limited edition จาก Moochie
                  ทันที 1ครั้ง
                </td>{" "}
              </tr>
              <br></br>
              <tr>
                <td></td>
                <td>Moochie Platinum Member :</td>
              </tr>
              <tr>
                <td>- </td>
                <td>
                  มียอดสั่งซื้อสะสมครบ 30,000 บาท ขึ้นไป
                  และรักษายอดตลอดทั้งเดือนไม่ต่ำกว่า 3,500 บาท
                </td>
              </tr>
              <tr>
                <td>- </td>
                <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
              </tr>
              <tr>
                <td>- </td>
                <td>
                  รับโค้ดส่งสินค้าฟรี 7 ครั้ง/เดือน สำหรับสั่งสินค้าออนไลน์
                </td>{" "}
              </tr>
              <tr>
                <td>- </td>
                <td>
                  รับส่วนลด 10% (สามารถใช้กับร้านออนไลน์ของทางแบรนด์เท่านั้น)
                </td>{" "}
              </tr>
              <tr>
                <td>- </td>
                <td>
                  ได้รับสิทธิ์ตรวจสุขภาพสัตว์เลี้ยงฟรี ปีละ 1 ครั้ง (Hato
                  wellness)
                </td>{" "}
              </tr>
              <tr>
                <td>- </td>
                <td>
                  รับ Gift set ของ premium รุ่น Limited edition จาก Moochie
                  ทันที 1ครั้ง
                </td>{" "}
              </tr>
              <br></br>
              <tr>
                <td></td>
                <td>
                  หมายเหตุ: กรณีไม่มียอดซื้อขั้นต่ำตามกำหนดในแต่ละเดือน
                  จะไม่ได้รับสิทธิประโยชน์ในเดือนนั้น ๆแต่จะไม่ถูกลดระดับสิทธิ์
                  member{" "}
                </td>{" "}
              </tr>
            </td>
          </tr>

          <tr>
            <td style={{ verticalAlign: "text-top" }}>6. </td>
            <td>
              ทุก ๆ ยอดสินค้า 100 บาท = 1 คะแนน
              (สามารถซื้อสินค้าเพื่อร่วมกิจกรรมสะสมคะแนนได้จากทุกช่องทาง)
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>7. </td>
            <td>
              ทุกคะแนนที่สะสมสามารถใช้แลกของพรีเมียม Moochie
              ได้ตามที่แบรนด์กำหนด
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>8. </td>
            <td>
              ยอดซื้อสินค้าที่สะสมได้สูงสุดตามลำดับตั้งแต่ ลำดับที่ 1 – 10
              มีสิทธิ์ได้รับรางวัลตามที่แบรนด์กำหนด
            </td>
          </tr>
        </table>
        <br></br>
        วิธีการร่วมกิจกรรม
        <table>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>1. </td>
            <td>
              สะสมยอดซื้อจากใบเสร็จทุกช่องทาง (Online shop & Pet shop หรือ งาน
              Event ของ Moochie ตลอดแคมเปญ)
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>2. </td>
            <td>
              อัปโหลดภาพใบเสร็จผ่านระบบ และรอ Admin approve ยอดใบเสร็จ
              (ตัดยอดใบเสร็จทุก 18.00 น. ของทุกวัน)
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>3. </td>
            <td>ทุกๆ ยอดซื้อ 100 บาท รับ 1 คะแนน</td>{" "}
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>4. </td>
            <td>คะแนนสะสมจากยอดซื้อใบเสร็จ จะแสดงผลในวันถัดไป</td>{" "}
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>5. </td>
            <td>
              สามารถร่วมสนุกได้โดย ลงทะเบียนผ่าน LINE OFFICIAL MOOCHIE:
              https://lin.ee/eszT5MR เท่านั้น
            </td>{" "}
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>6. </td>
            <td>
              ผู้ร่วมกิจกรรมต้องกรอกข้อมูลตามที่ระบบกำหนดให้ครบถ้วน
              เพื่อรับสิทธิ์ตามที่แบรนด์กำหนด
            </td>{" "}
          </tr>
        </table>
        <br></br>
        กำหนดการประกาศรางวัล
        <p style={{ marginLeft: "5%" }}>
          กำหนดประกาศผู้ได้รับรางวัลตามยอดซื้อสะสม (Top spender) 10
          ลำดับวันศุกร์ ที่ 6 ธันวาคม 2567 เวลา 15.00 น. (วัน
          และเวลาอาจมีการเปลี่ยนแปลงตามที่บริษัทกำหนด) ทางเว็บไซต์กิจกรรม
          โดยสามารถเข้าผ่าน LINE OFFICIAL MOOCHIE: https://lin.ee/eszT5MR
        </p>
        <br></br>
        วิธีการประกาศรางวัล
        <p style={{ marginLeft: "5%" }}>
          บริษัทฯ จะประกาศผู้ได้รับรางวัลตามยอดซื้อสะสม (Top spender) 10 ลำดับ
          วันศุกร์ ที่ 6 ธันวาคม 2567 เวลา 15.00 น. (วัน
          และเวลาอาจมีการเปลี่ยนแปลงตามที่บริษัทกำหนด) ทางเว็บไซต์กิจกรรม
          โดยสามารถเข้าผ่าน LINE OFFICIAL MOOCHIE: https://lin.ee/eszT5MR
        </p>
        <br></br>
        วิธีการร่วมกิจกรรม
        <table>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>1. </td>
            <td>
              กิจกรรมเริ่มตั้งแต่วันที่ 4 มีนาคม 2567 ถึง 30 พฤศจิกายน 2567
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>2. </td>
            <td>
              สิ่งของรางวัลไม่สามารถนำไปแลกเป็นเงินสดหรือสิ่งของอื่นใดได้
              ไม่สามารถโอนให้ผู้อื่นได้ และไม่มีการจ่ายเงินเป็นส่วนประกอบ
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>3. </td>
            <td>
              การจับรางวัลจะประกาศผู้ได้รับรางวัลตามยอดซื้อสะสม (Top spender) 10
              ลำดับ{" "}
            </td>{" "}
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>4. </td>
            <td>
              ผู้โชคดีที่ได้รับรางวัลทั้งที่มีภูมิลำเนาในกรุงเทพมหานคร
              และต่างจังหวัดต้องนำหลักฐานบัตรประจำตัวประชาชน พร้อมสำเนา 1 ชุด
              มาเป็นหลักฐานในการขอรับรางวัลด้วยตนเองที่บริษัท ไทยออซัม จำกัด
              (มหาชน) สำนักงานแห่งใหญ่ตั้งอยู่เลขที่ 108/126 ซอยแจ้งวัฒนะ 20
              ถนนแจ้งวัฒนะ ตำบลปากเกร็ด อำเภอปากเกร็ด จังหวัดนนทบุรี
              ในวันและเวลาทำการภายในระยะ 7 วันนับแต่วันที่ประกาศรายชื่อผู้โชคดี
              หากเลยกำหนดถือว่าสละสิทธิ์ และบริษัทฯ
              จะมอบของรางวัลดังกล่าวให้กับองค์กรสาธารณกุศลต่อไป
            </td>{" "}
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>5. </td>
            <td>
              บริษัทฯ มีสิทธิ์คัดเลือกรายชื่อผู้ได้รับรางวัลสำรองไว้
              สำหรับกรณีที่ผู้มีสิทธิ์ได้รับรางวัล และ/หรือ
              ผู้ได้รับรางวัลต้องถูกตัดสิทธิ์จากการรับรางวัลเนื่องจากมีคุณสมบัติไม่ครบถ้วนหรือไม่ปฏิบัติตามกติกาและเงื่อนไขของการจับรางวัลที่กำหนดไว้
            </td>{" "}
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>6. </td>
            <td>
              ผู้โชคดีที่ได้รับรางวัลมูลค่า 1,000 บาทขึ้นไป จะต้องหักภาษี ณ
              ที่จ่าย ของมูลค่ารางวัล ตามคำสั่ง กรมสรรพากร ที่ ท.ป. 4/2528
              ประกอบคำสั่งกรมสรรพากรที่ ท.ป. 104/2544 และ
              ค่าธรรมเนียมและค่าภาษีอื่น ๆ
              ที่เกิดขึ้นนอกเหนือจากมูลค่าสิ่งของรางวัลที่ได้รับ
              ผู้โชคดีจะต้องเป็นผู้รับผิดชอบค่าใช้จ่ายเอง
            </td>{" "}
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>7. </td>
            <td>
              ค่าธรรมเนียม ค่าภาษีอื่น ค่าใช้จ่ายอื่น และ ส่วนเกินมูลค่าสินค้า
              ที่เกิดขึ้นนอกเหนือจากมูลค่าสิ่งของรางวัลที่ได้รับ
              ผู้โชคดีจะต้องเป็นผู้รับผิดชอบค่าใช้จ่ายเอง สอบถามข้อมูล
              และตรวจสอบเงื่อนไขเพิมเติมได้ที่ .บริษัท ไทยออซัม จำกัด (มหาชน)
              02-8215545
            </td>{" "}
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>8. </td>
            <td>
              บริษัทฯ เป็นเพียงผู้จัดหาของรางวัลเพื่อรายการชิงโชคนี้เท่านั้น
              โดยบริษัทฯ มิได้มีส่วนเกี่ยวข้องกับการผลิต และ/หรือการจำหน่าย
              และ/หรือ การให้บริการ ในของรางวัลที่ระบุไว้แต่อย่างใด ดังนั้น
              บริษัทฯ จึงไม่ต้องรับผิดชอบในความเสียหาย บุบสลาย ชำรุดบกพร่อง
              และ/หรือ ในเหตุอื่นใดอันเกิดขึ้นกับของรางวัลทั้งสิ้น
            </td>{" "}
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>9. </td>
            <td>
              พนักงานบริษัท ไทยออซัม จำกัด (มหาชน) ,
              บริษัทตัวแทนโฆษณาที่เกี่ยวข้อง คณะกรรมการดำเนินรายการและครอบครัว
              ไม่มีสิทธิ์เข้าร่วมชิงรางวัลในรายการ
            </td>{" "}
          </tr>
          <tr>
            <td style={{ verticalAlign: "text-top" }}>10. </td>
            <td>
              สำหรับกิจรรมสะสมคะแนนเพื่อลุ้นรับรางวัล
              ทางทีมจะตรวจสอบข้อมูลเมื่อครบถ้วน และถูกต้องแล้ว
              จะดำเนินการทยอยจัดส่งของรางวัลไปตามที่อยู่ที่ได้ลงทะเบียน
              โดยการจัดส่งของรางวัลจะจัดส่งเป็นรอบๆทุก 7 วัน
              ตลอดจนหมดระยะเวลาของกิจกรรม
            </td>{" "}
          </tr>
        </table>
      </Col>
    </Row>
  );
};

export default Policy;

import React, { useEffect } from "react";
import { Spin, Col, Row } from "antd";
import { useHistory } from "react-router-dom";

const LineLoginProd = () => {
  const liff = window.liff;
  let history = useHistory();

  // get liff id
  const liffIdProd = sessionStorage.getItem("liffIdProd");

  useEffect(() => {
    liff
      .init({ liffId: liffIdProd })
      .then(async () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          let lineTokenProd = liff.getAccessToken();
          localStorage.setItem("lineTokenProd", lineTokenProd);
          // localStorage.setItem("lineAccessToken", lineTokenProd);

          liff.getProfile().then((profile) => {
            localStorage.setItem("lineProfileProd", JSON.stringify(profile));
            history.push("/login");
          });

          // history.push("/home");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <div className="backgroundHome">
        <Row justify="space-around" align="middle" className="h-100">
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LineLoginProd;
